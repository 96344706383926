<template>
    <div class="page-rooter-fff">
        <nav-bar v-if="appTypeStr==='ios' || appTypeStr==='and'" header-title="" :show-back="true" :header-back="back"
                 :headerBack="headerBack"/>
        <app-page>
            <div class="main all-center" v-if="!canIUse">
                <img class="empty-img"
                     src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/selfGroupClass/emptyPage.png">
                <div class="empty-text">
                    当前版本过低无法展示<br>
                    将为您自动跳转至小程序
                </div>
            </div>
        </app-page>
    </div>
</template>

<script>
import wx from "weixin-js-sdk"
import appMixin from "@/mixin/appMixin";
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import {
    appGetCityId,
    appGetLocal,
    appGetUser,
    appGetDevice,
    appGetLocalVenue,
    appGetToken,
    appBack,
    appType, closeWindow, getAppVersion, gotoAppPage
} from "@/lib/appMethod";
import {compareVersion} from "@/lib/utils";
import userMixin from "@/mixin/userMixin";

export default {
    data() {
        return {
            appTypeStr: '',
            time: 2,
            interver: '',
            isUndo: '',
            caseStr: '',
            canIUse: false
        };
    },
    mixins: [userMixin],
    components: {
        navBar,
        appPage
    },
    async created() {
        this.appVersion = await getAppVersion()
        try {
            this.canIUse = (compareVersion(this.appVersion, '2.40.09') >= 0)
        }catch (e){
            this.canIUse = false
        }
        this.appTypeStr = appType()
        if(this.canIUse){
            this.$toast.loading('跳转中...')
            setTimeout(() => {
                gotoAppPage(8)
            }, 200)
        }else{
            setTimeout(() => {
                this.headerBack()
            }, 200)
        }
    },
    mounted() {
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "hidden") {
                closeWindow();
            }
        })
    },
    methods: {
        headerBack() {
            if (this.appTypeStr === 'ios') {
                window.webkit.messageHandlers.pushMiniProgram.postMessage({
                    miniProgramType:  window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2,
                    userName: 'gh_7f5b57b6a76e',
                    path: `/pages/miniCamp/index`
                })
            } else if (this.appTypeStr === 'and') {
                App.web2native('31', JSON.stringify({userName: 'gh_7f5b57b6a76e', path: `/pages/miniCamp/index`}))
            }
        },
        jump() {

        }
    },
};
</script>

<style lang="less" scoped>
.page-rooter-fff {
    .c-img {
        width: 100%;
        position: relative;

        img {
            width: 100%;
            display: block;
        }

        .btn {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;

            .btn-img {
                display: block;
                margin: 0 auto;
                width: 43.7333333vw !important;
            }
        }
    }
}

.all-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.main {
    width: 100%;
    overflow: hidden;
}

.empty-img {
    display: block;
    margin: 0 auto;
    width: 6.25rem;
    height: 6.25rem;
}

.empty-text {
    margin-top: 1.5rem;
    height: 2.75rem;
    font-size: 0.88rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6C727A;
    line-height: 1.38rem;
    text-align: center;
}
</style>
